<template>
  <div>
    <header class="header__section">
<!--      <b-alert v-if="alert.type==2" class="mb-0 text-center" style="border-radius:0;font-size:28px;" v-model="alert.ifAlert"-->
<!--               variant="primary" dark dismissible>{{-->
<!--          alert.content-->
<!--        }}-->
<!--      </b-alert>-->
<!--      <b-alert v-if="alert.type == 1" class="mb-0 text-center imageAlert"-->
<!--               v-model="alert.ifAlert" variant="primary" dark dismissible>-->
<!--        <img :src="alert.presignedUrl"/>-->
<!--      </b-alert>-->
      <div class="main__header header__sticky">
        <div class="container">
          <div class="main__header--inner position-relative d-flex justify-content-between align-items-center">
            <div class="offcanvas__header--menu__open " style="margin-right: .625rem">
              <div class="offcanvas__header--menu__open--btn" href="javascript:void(0)"
                 @click.stop="offcanvasHeaderMenuOpenBtn" data-offcanvas="">
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon offcanvas__header--menu__open--svg"
                     viewBox="0 0 512 512">
                  <path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                        stroke-width="32" d="M80 160h352M80 256h352M80 352h352"></path>
                </svg>
                <span class="visually-hidden">Offcanvas Menu Open</span>
              </div>
            </div>
            <div class="main__logo">
              <h1 class="main__logo--title" style="margin-bottom: 0">
                <router-link class="main__logo--link" to="/" v-if="navLight !== true">
                  <img class="main__logo--img logo__light" src="/images/logo-dark.png" height="32" alt=""/>
                </router-link>
                <router-link class="main__logo--link" to="/" v-else>
                  <img class="main__logo--img  l-dark" src="/images/logo-dark.png" height="32" alt=""/>
                  <img class="main__logo--img  l-light" src="/images/logo-light.png" height="32" alt=""/>
                </router-link>
                <!--              <a class="main__logo&#45;&#45;link" href="/">-->
                <!--                <img class="main__logo&#45;&#45;img logo__light" src="/images/logo-light.png">-->
                <!--              </a>-->
              </h1>
            </div>
            <div class="header__menu d-none d-lg-block">
              <nav class="header__menu--navigation" style="margin-left: 1.5rem;">
                <ul class="d-flex">
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link " :class="{path}" id="home" href="/">Home</a>-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items" style="display:flex;">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/plans" id="plans">Plans</a>-->
                  <!--&lt;!&ndash;                  <img style="align-self: flex-start;width: 24px;height: 18px;margin-top: -4px;" src="/assets/img/icon/hot2.png">&ndash;&gt;-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/dashboard" id="dashboard">Dashboard</a>-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/referralprogram" id="referralprogram">Referral</a>-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/faqs" id="faqs">FAQ</a>-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/blog" id="blog">Blog</a>-->
                  <!--                </li>-->
                  <!--                <li class="header__menu&#45;&#45;items">-->
                  <!--                  <a class="header__menu&#45;&#45;link" href="/reviews" id="reviews">Reviews</a>-->
                  <!--                </li>-->
                  <li class="header__menu--items" v-for="item in linkList" :key="item.path" style="margin-left: .025rem;">
                    <a class="header__menu--link " :class="{'header__menu--items-active': item.path == path}"
                       :href="item.path" :id="item.path">{{ item.title }} </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="header__account" style="margin-left: auto">
              <ul class="d-flex align-items-center">
                <li class="header__account--items">
                  <div class="navbar-collapse" id="navbarNavDarkDropdown">
                    <div class="dropdown">
                      <a class="btn btn-outline-primary dropdown-toggle header__menu--link"
                         :class="{'dropdown-toggle': isShowLanguagePanel}" @click="openLanguagePanel"
                         href="javascript: void (0)"
                         style="font-weight:400;" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                         aria-expanded="false">
                        <i class="fa fa-globe"></i> Language
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" ref="dropdownMenuLink"
                           v-show="isShowLanguagePanel">
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|en');"><i class="fi fi-gb"></i> English</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|de');"><i class="fi fi-de"></i> Deutsch</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|fr');"><i class="fi fi-fr"></i> Français</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|ja');"><i class="fi fi-jp"></i> 日本語</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|ko');"><i class="fi fi-kr"></i> 한국어</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|pt');"><i class="fi fi-pt"></i> Português</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|ru');"><i class="fi fi-ru"></i> Русский</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|sp');"><i class="fi fi-es"></i> Español</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|tr');"><i class="fi fi-tr"></i> Türk</a>
                        <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                           @click="doGTranslate('en|vi');"><i class="fi fi-vi"></i> Tiếng Việt</a>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="login" class="header__account--items d-sm-u-none">
                  <div class="dropdown">
                    <!--                    /profile-->
                    <a href="#" class="dropdown-toggle header__menu--link"
                       @click="isShowMyCenterPanel = !isShowMyCenterPanel"> {{ email }}</a>
                    <div class="dropdown-menu" style="left: auto;right: 0" aria-labelledby="dropdownMenuLink"
                         ref="dropdownMenuMyCenter"
                         v-show="isShowMyCenterPanel">
                      <router-link to="/profile/setting">
                      <a class="dropdown-item" style="font-weight:400;" @click="isShowMyCenterPanel = !isShowMyCenterPanel"
                      > Account Settings</a>
                      </router-link>
                      <a class="dropdown-item header__menu--link" style="font-weight:400;" href="#"
                         @click="logout">Sign out</a>
                    </div>
                  </div>
                </li>
                <li v-else class="header__account--items d-sm-u-none">
                  <a class=" primary__btn_login" href="/auth-login">
                    Sign in
                  </a>
                  <a class=" primary__btn_signup " style="margin-left: .25rem" href="/auth-signup">
                    Sign up
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      <div class="offcanvas__header" :class="{'open': offcanvasHeaderMenuOpen}">
        <div class="offcanvas__inner">
          <div class="offcanvas__logo">
            <router-link class="offcanvas__logo_link" to="/" v-if="navLight !== true">
              <img class="logo__light logo__light" src="/images/logo-dark.png" height="32" alt=""/>
            </router-link>
            <router-link class="offcanvas__logo_link" to="/" v-else>
              <img class="logo__light  l-dark" src="/images/logo-dark.png" height="32" alt=""/>
              <img class="logo__light  l-light" src="/images/logo-light.png" height="32" alt=""/>
            </router-link>
            <!--          <a class="offcanvas__logo_link" href="/">-->
            <!--            <img class="logo__light" src="/assets/img/logo/logo.png">-->
            <!--          </a>-->
            <button class="offcanvas__close--btn" data-offcanvas="" @click.s.stop="offcanvasHeaderMenuCloseBtn">close</button>
          </div>
          <nav class="offcanvas__menu">
            <ul class="offcanvas__menu_ul">
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="home2" href="/">Home</a>-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li" style="display:flex;">-->
              <!--              <a class="offcanvas__menu_item" id="plans2" href="/plans">Plans</a>-->
              <!--&lt;!&ndash;              <img style="align-self: flex-start;margin-left: -20px;width: 24px;" src="/assets/img/icon/hot2.png">&ndash;&gt;-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="dashboard2" href="/dashboard">Dashboard</a>-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="referralprogram2" href="/referralprogram">Referral</a>-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="faqs2" href="/faqs">FAQ</a>-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="blog2" href="/blog">Blog</a>-->
              <!--            </li>-->
              <!--            <li class="offcanvas__menu_li">-->
              <!--              <a class="offcanvas__menu_item" id="reviews2" href="/reviews">Reviews</a>-->
              <!--            </li>-->
              <li class="offcanvas__menu_li" v-for="item in linkList" :key="item.path">
                <a class="offcanvas__menu_item" :class="{'header__menu--items-active': item.path == path}"
                   :href="item.path" :id="item.path">{{ item.title }}</a>
              </li>
            </ul>
            <div v-if="login" class="header__account offcanvas__account">
              <ul class=" align-items-center">
                <li class="header__account--items">
                  <a href="/profile/overview"> {{ email }}</a>
                </li>
                <br>
                <li class="header__account--items">
                  <a class=" primary__btn_signup " @click="logout" href="#">
                    Sign out
                  </a>
                </li>

              </ul>
            </div>
            <div v-else class="header__account offcanvas__account">
              <ul class="d-flex align-items-center">
                <li class="header__account--items">
                  <a class=" primary__btn_login" href="/auth-login">
                    Sign in
                  </a>
                  <a class=" primary__btn_signup " style="margin-left: 0.25rem" href="/auth-signup">
                    Sign up
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

    </header>
  </div>
</template>

<script>
import storage from "@/storage";
import homeApi from "@/apis/homeApi";
import {Loading} from "element-ui";
import accountApi from "@/apis/accountApi";

export default {
  name: "navbar4",
  data() {
    return {
      navLight: false,
      path: '',
      linkList: [
        {title: 'Home', path: '/'},
        {title: 'Plans', path: '/plans'},
        {title: 'Dashboard', path: '/profile'},
        {title: 'Referral', path: '/referral'},
        {title: 'FAQ', path: '/faq'},
        // {title: 'Blog', path: '/index-blog'},
        {title: 'Reviews', path: '/reviews'},
        {title: 'About Us', path: '/about-us'},
      ],
      login: false,
      email: '',
      isShowLanguagePanel: false,
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
      isShowMyCenterPanel: false,
      offcanvasHeaderMenuOpen: false
    }
  },
  watch: {},
  mounted() {
    const that = this
    let userId = storage.localGet("userId")
    if (userId) {
      const flag = window.matchMedia("(min-width: 992px)").matches
      this.login = true
      this.email = userId.email
      const uA = navigator.userAgent;
      const isAndroid = uA.indexOf('Android') > -1 || uA.indexOf('Adr') > -1; //android终端
      const isiOS = !!uA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS || isAndroid) {
        if(this.login && !flag){
          // this.linkListShow = false
          // this.loginNavShow = true
          this.linkList = [
            {title: 'Dashboard', path: '/profile/overview'},
            {title: 'My Bills', path: '/profile/transactions'},
            {title: 'Deposits', path: '/profile/deposits'},
            {title: 'Withdraws', path: '/profile/withdraws'},
            {title: 'My Orders', path: '/profile/myOrders'},
            {title: 'Buy Plans', path: '/plans'},
            {title: 'Referral', path: '/profile/referral'},
            {title: 'Settings', path: '/profile/setting'}
          ]
        }
      }
    }
    // window.onscroll = function () {
    //   onwindowScroll();
    // };
    console.log(this.$route)
    this.path = this.$route.path
    this.offcanvasHeader()
    const headerStickyWrapper = document.querySelector("header");
    const headerStickyTarget = document.querySelector(".header__sticky");
    if (headerStickyTarget) {
      let headerHeight = headerStickyWrapper.clientHeight;
      window.addEventListener("scroll", function () {
        let StickyTargetElement = that.TopOffset(headerStickyWrapper);
        let TargetElementTopOffset = StickyTargetElement.top;
        if (window.scrollY > TargetElementTopOffset) {
          headerStickyTarget.classList.add("sticky");
        } else {
          headerStickyTarget.classList.remove("sticky");
        }
      });
    }


    if (this.GTranslateGetCurrentLang() != null) {
      let lang_html = document.querySelector('div.switcher div.option')
      console.log(lang_html)
      // var lang_html = jQuery('div.switcher div.option').find(
      //     'img[alt="' + GTranslateGetCurrentLang() + '"]')
      //     .parent().html();
      // if (typeof lang_html != 'undefined')
      //   jQuery('div.switcher div.selected a').html(
      //       lang_html.replace('data-gt-lazy-', ''));
    }

    // homeApi.notification(res => {
    //   this.alert = res.data
    // })
    const createVConsole = () => {
      const wind = window
      const script = document.createElement('script')
      script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.8.1/vconsole.min.js'
      script.async = true
      script.onload = () => {
        const VConsole = new wind.VConsole()
        console.log('VConsole loaded', VConsole)
      }
      document.body.appendChild(script)
    }
    // createVConsole()
    let body = document.querySelector("body");
    // window.addEventListener("resize", function () {
    //   if (window.outerWidth >= 992) {
    //     // offcanvasHeader.classList.remove("open");
    //     that.offcanvasHeaderMenuOpen = false;
    //     body.classList.remove("mobile_menu_open");
    //   } else {
    //     that.offcanvasHeaderMenuOpen = true;
    //     body.classList.add("mobile_menu_open");
    //   }
    // });
  },
  destroyed() {
    // let body = document.querySelector("body"),
    // offcanvasHeader = document.querySelector(".offcanvas__header");
    window.removeEventListener("resize", function () {

    });
  },
  methods: {
    offcanvasHeaderMenuOpenBtn() {
      this.offcanvasHeaderMenuOpen = true;
      setTimeout(() => {
        let body = document.querySelector("body");
        let offcanvasHeader = document.querySelector(".offcanvas__header");
        body.classList.add("mobile_menu_open");
        // offcanvasHeader.classList.add("open");
      })
    },
    offcanvasHeaderMenuCloseBtn() {
      this.offcanvasHeaderMenuOpen = false;
      setTimeout(() => {
        let offcanvasClose = document.querySelector(".offcanvas__close--btn");
        let offcanvasHeader = document.querySelector(".offcanvas__header");
        let body = document.querySelector("body");
        // offcanvasHeader.classList.remove("open");
        body.classList.remove("mobile_menu_open");
      })
    },
    handleClick(path){
      alert(path)
      // alert(this.$route.path)

    },
    logout() {
      const load = Loading.service({
        lock: true,
        text: 'Sign out...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      accountApi.logout(() => {
        load.close()
        storage.localDel("userId")
        localStorage.clear()
        if (location.href != "/") {
          location.href = "/"
        } else {
          location.reload()
        }

      })
    },
    openLanguagePanel() {
      // this.$refs.dropdownMenuLink.style.display = 'block'
      console.log(111)
      this.isShowLanguagePanel = !this.isShowLanguagePanel;
    },
    GTranslateGetCurrentLang() {
      var keyValue = document['cookie']
          .match('(^|;) ?googtrans=([^;]*)(;|$)');
      return keyValue ? keyValue[2].split('/')[2] : null;
    },
    GTranslateFireEvent(element, event) {
      try {
        if (document.createEventObject) {
          var evt = document.createEventObject();
          element.fireEvent('on' + event, evt)
        } else {
          var evt = document.createEvent('HTMLEvents');
          evt.initEvent(event, true, true);
          element.dispatchEvent(evt)
        }
        // $(".skiptranslate").css("display", "none");
        document.getElementsByClassName('skiptranslate')[0].style.display = "none"
        $("body").removeAttr("style");
        document.body.style = ""
      } catch (e) {
      }
    },
    doGTranslate(lang_pair) {
      const vm = this;
      try {
        if (lang_pair.value)
          lang_pair = lang_pair.value;
        if (lang_pair == '')
          return;
        var lang = lang_pair.split('|')[1];
        if (vm.GTranslateGetCurrentLang() == null
            && lang == lang_pair.split('|')[0])
          return;
        var teCombo;
        var sel = document.getElementsByTagName('select');
        for (var i = 0; i < sel.length; i++)
          if (sel[i].className.indexOf('goog-te-combo') != -1) {
            teCombo = sel[i];
            break;
          }
        if (document.getElementById('google_translate_element2') == null
            || document.getElementById('google_translate_element2').innerHTML.length == 0
            || teCombo.length == 0 || teCombo.innerHTML.length == 0) {
          setTimeout(function () {
            vm.doGTranslate(lang_pair)
          }, 500)
        } else {
          teCombo.value = lang;
          vm.GTranslateFireEvent(teCombo, 'change');
          vm.GTranslateFireEvent(teCombo, 'change')
        }
        this.isShowLanguagePanel = false
      } catch (e) {

        console.log(e)
      }
    },
    TopOffset(el) {
      let rect = el.getBoundingClientRect(), scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return {top: rect.top + scrollTop};
    },
    offcanvasHeader() {
      const that = this;
      try {
        const offcanvasOpen = document.querySelector(".offcanvas__header--menu__open--btn"),
            offcanvasClose = document.querySelector(".offcanvas__close--btn"),
            offcanvasHeader = document.querySelector(".offcanvas__header"),
            offcanvasMenu = document.querySelector(".offcanvas__menu"), body = document.querySelector("body");
        if (offcanvasMenu) {
          offcanvasMenu.querySelectorAll(".offcanvas__sub_menu").forEach(function (ul) {
            const subMenuToggle = document.createElement("button");
            subMenuToggle.classList.add("offcanvas__sub_menu_toggle");
            ul.parentNode.appendChild(subMenuToggle);
          });
        }
        // if (offcanvasOpen) {
        //   offcanvasOpen.addEventListener("click", function (e) {
        //     e.preventDefault();
        //     if (e.target.dataset.offcanvas != undefined) {
        //       offcanvasHeader.classList.add("open");
        //       body.classList.add("mobile_menu_open");
        //     }
        //   });
        // }
        // if (offcanvasClose) {
        //   offcanvasClose.addEventListener("click", function (e) {
        //     e.preventDefault();
        //     if (e.target.dataset.offcanvas != undefined) {
        //       offcanvasHeader.classList.remove("open");
        //       body.classList.remove("mobile_menu_open");
        //     }
        //   });
        // }
        let mobileMenuWrapper = document.querySelector(".offcanvas__menu_ul");
        if (mobileMenuWrapper) {
          mobileMenuWrapper.addEventListener("click", function (e) {
            let targetElement = e.target;
            if (targetElement.classList.contains("offcanvas__sub_menu_toggle")) {
              const parent = targetElement.parentElement;
              if (parent.classList.contains("active")) {
                targetElement.classList.remove("active");
                parent.classList.remove("active");
                parent.querySelectorAll(".offcanvas__sub_menu").forEach(function (subMenu) {
                  subMenu.parentElement.classList.remove("active");
                  subMenu.nextElementSibling.classList.remove("active");
                  that.slideUp(subMenu);
                });
              } else {
                targetElement.classList.add("active");
                parent.classList.add("active");
                that.slideDown(targetElement.previousElementSibling);
                that.getSiblings(parent).forEach(function (item) {
                  item.classList.remove("active");
                  item.querySelectorAll(".offcanvas__sub_menu").forEach(function (subMenu) {
                    subMenu.parentElement.classList.remove("active");
                    subMenu.nextElementSibling.classList.remove("active");
                    that.slideUp(subMenu);
                  });
                });
              }
            }
          });
        }
        // if (offcanvasHeader) {
        //   document.addEventListener("click", function (event) {
        //     if (!event.target.closest(".offcanvas__header--menu__open--btn") && !event.target.classList.contains(".offcanvas__header--menu__open--btn".replace(/\./, ""))) {
        //       if (!event.target.closest(".offcanvas__header") && !event.target.classList.contains(".offcanvas__header".replace(/\./, ""))) {
        //         offcanvasHeader.classList.remove("open");
        //         body.classList.remove("mobile_menu_open");
        //       }
        //     }
        //   });
        // }
        // if (offcanvasHeader) {
        //   window.addEventListener("resize", function () {
        //     if (window.outerWidth >= 992) {
        //       offcanvasHeader.classList.remove("open");
        //       body.classList.remove("mobile_menu_open");
        //     }
        //   });
        // }
      } catch (e) {
        console.log(e)
        alert(JSON.stringify(e))
      }

    },
    getSiblings(elem) {
      const siblings = [];
      let sibling = elem.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    },
    slideUp(target, time) {
      const duration = time ? time : 500;
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.boxSizing = "border-box";
      target.style.height = target.offsetHeight + "px";
      target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      window.setTimeout(function () {
        target.style.display = "none";
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
    slideDown(target, time) {
      const duration = time ? time : 500;
      target.style.removeProperty("display");
      let display = window.getComputedStyle(target).display;
      if (display === "none") display = "block";
      target.style.display = display;
      const height = target.offsetHeight;
      target.style.overflow = "hidden";
      target.style.height = 0;
      target.offsetHeight;
      target.style.boxSizing = "border-box";
      target.style.transitionProperty = "height, margin, padding";
      target.style.transitionDuration = duration + "ms";
      target.style.height = height + "px";
      window.setTimeout(function () {
        target.style.removeProperty("height");
        target.style.removeProperty("overflow");
        target.style.removeProperty("transition-duration");
        target.style.removeProperty("transition-property");
      }, duration);
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/style/common.scss';

.offcanvas__header--menu__open {
  line-height: 1;
  display: none
}

@media only screen and (max-width: 991px) {
  .offcanvas__header--menu__open {
    display: block
  }
}

.offcanvas__header--menu__open--svg {
  width: 32px
}

.offcanvas__header--menu__open--btn > * {
  pointer-events: none
}

.offcanvas__header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  height: 100vh;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  opacity: 0;
  visibility: hidden;
  padding-bottom: 120px
}

@media only screen and (min-width: 480px) {
  .offcanvas__header {
    max-width: 320px
  }
}

.offcanvas__header.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
  visibility: visible
}

.offcanvas__header.open ~ .offcanvas-overlay {
  visibility: visible;
  opacity: .75
}

.offcanvas-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  background-color: #000000;
}

.offcanvas__inner {
  position: relative;
  height: 100%
}

.offcanvas__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 15px
}

.offcanvas__logo img {
  max-width: 135px
}

.offcanvas__close--btn {
  position: relative;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  text-indent: -9999px;
  border: none;
  background-color: transparent
}

.offcanvas__close--btn::before, .offcanvas__close--btn::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #000
}

.offcanvas__close--btn::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.offcanvas__menu {
  overflow-y: auto;
  height: 100%
}

.offcanvas__menu_ul {
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none
}

.offcanvas__menu_li {
  position: relative;
  border-bottom: 1px solid #e4e4e4;
}

.offcanvas__menu_li:first-child {
  border-top: 1px solid #e4e4e4;
}

.offcanvas__menu_item {
  line-height: 1;
  display: block;
  padding: 15px 20px;
  text-transform: uppercase
}

.offcanvas__menu_item_active {
  color: #few343;
}

.offcanvas__sub_menu {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none
}

.offcanvas__sub_menu_li {
  position: relative;
  border-top: 1px solid #e4e4e4;
}

.offcanvas__sub_menu_item {
  line-height: 1;
  display: block;
  padding: 15px 0 15px 30px
}

.offcanvas__sub_menu_item ~ .offcanvas__sub_menu .offcanvas__sub_menu_item {
  padding-left: 40px
}

.offcanvas__sub_menu_toggle {
  font-size: 20px;
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 2.875rem;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent
}

.offcanvas__sub_menu_toggle::before, .offcanvas__sub_menu_toggle::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #000
}

.offcanvas__sub_menu_toggle:not(.active)::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
  transform: translateX(-50%) translateY(-50%) rotate(90deg)
}

.offcanvas__account {
  margin-top: 1.125rem;
  padding: 0 1.25rem
}

.header__sticky.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  background: #f9f9f9;
  left: 0;
  z-index: 99;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, .15);
  box-shadow: 0 0 7px rgba(0, 0, 0, .15);
  -webkit-transition: .3s;
  transition: .3s
}

.header__transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99
}

.main__logo {
  //transform: scale(0.5);
}

@media only screen and (max-width: 991px) {
  .main__logo {
    margin-right: -70px
  }
}

@media only screen and (max-width: 575px) {
  .main__logo {
    margin-right: -55px
  }
}

@media only screen and (max-width: 400px) {
  .main__logo {
    margin-right: -20px
  }
}

.main__logo--title {

  line-height: 1.25rem;
}

.main__logo--img {
  max-width: 220px
}

@media only screen and (max-width: 1240px) {
  .main__logo--img {
    max-width: 180px
  }
}

@media only screen and (max-width: 1199px) {
  .main__logo--img {
    max-width: 150px
  }
}

@media only screen and (max-width: 767px) {
  .main__logo--img {
    max-width: 130px
  }
}

.main__header {
  font-size: 0.9375rem;
  line-height: 1.625rem;
  border-bottom: 1px solid #e4e4e4;
}

@media only screen and (max-width: 991px) {
  .main__header {
    padding: 1.125rem 0
  }
}

.header__account--items {
  margin-right: .5rem
}

@media only screen and (min-width: 576px) {
  .header__account--items {
    margin-right: .625rem
  }
}

@media only screen and (min-width: 768px) {
  .header__account--items {
    margin-right: 0.8125rem
  }
}

@media only screen and (min-width: 1200px) {
  .header__account--items {
    margin-right: 1.125rem
  }
}

@media only screen and (min-width: 1366px) {
  .header__account--items {
    margin-right: 1.25rem
  }
}

.header__account--items:last-child {
  margin-right: 0
}

@media only screen and (max-width: 575px) {
  .header__account--items.switcher {
    position: fixed;
    right: 10px;
    top: 85px;
    z-index: 9
  }
}

@media only screen and (max-width: 991px) {
  .header__account--items.d-sm-u-none {
    display: none
  }
}

@media only screen and (min-width: 992px) {
  .header__account--search__items.mobile__d--block {
    display: none
  }
}

.header__account--btn {
  position: relative;
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  border: 1px solid #e4e4e4;;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent
}

@media only screen and (min-width: 480px) {
  .header__account--btn {
    width: 2.125rem;
    height: 2.125rem
  }
}

@media only screen and (min-width: 768px) {
  .header__account--btn {
    width: 2.25rem;
    height: 2.25rem
  }
}

@media only screen and (min-width: 1200px) {
  .header__account--btn {
    width: 2.5rem;
    height: 2.5rem
  }
}

.header__account--btn:hover {
  color: #6610f2;
  border-color: #6610f2
}

.header__account--btn > * {
  pointer-events: none
}

@media only screen and (max-width: 575px) {
  .header__account--btn.switcher__mode--btn {
    background: #e4e4e4;
  }
}

@media only screen and (max-width: 479px) {
  .header__account--btn svg {
    width: 16px
  }
}

.header__bottom {
  -webkit-box-shadow: 0 3px 8px rgba(71, 71, 71, .16);
  box-shadow: 0 3px 8px rgba(71, 71, 71, .16)
}

.header__menu--items {
  margin-right: 1.25rem;
  position: relative;
  padding: 1.5625rem 0
}

@media only screen and (min-width: 992px) {
  .header__menu--items {
    margin-right: 1.125rem
  }
}

@media only screen and (min-width: 1200px) {
  .header__menu--items {
    margin-right: 1.5625rem;
    padding: 1.875rem 0
  }
}

@media only screen and (min-width: 1366px) {
  .header__menu--items {
    margin-right: 1.75rem
  }
}

@media only screen and (min-width: 1600px) {
  .header__menu--items {
    padding: 2.1875rem 0
  }
}

.header__menu--items:last-child {
  margin-right: 0
}

.header__menu--items:hover .header__menu--link {
  color: #0d6efd
}

.header__menu--items-active {
  color: #0d6efd
}

.header__menu--items:hover .header__menu--link::before {
  width: 100%;
  left: 0;
  right: 0
}

.header__menu--items:hover .header__sub--menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1
}

.header__menu--items:hover .header__mega--menu {
  visibility: visible;
  margin-top: 0;
  opacity: 1
}

.header__menu--link {
  font-size: 0.875rem;
  line-height: 1.875rem;
  position: relative;
  padding-bottom: .125rem;
  font-weight: 800
}

@media only screen and (min-width: 1200px) {
  .header__menu--link {
    font-size: 1rem
  }
}

.header__menu--link::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: #0d6efd;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s
}

.menu__arrowdown--icon {
  color: #0d6efd
}

.header__sub--menu {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 230px;
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  list-style: none;
  //-webkit-transition: var(--transition);
  transition: all 0.3s ease 0s;
  opacity: 0;
  background-color: #ffff;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
  border-radius: 10px
}

.header__sub--menu__items {
  position: relative
}

.header__sub--menu__items:hover .header__multi--label__sub--menu {
  opacity: 1;
  visibility: visible;
  top: 0
}

.header__sub--menu__link {
  font-size: 0.9375rem;
  display: block;
  padding: 10px 0
}

.header__sub--menu__link:hover {
  color: #0d6efd
}

.mega__menu--items {
  position: static
}

.header__mega--menu {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  margin-top: 20px;
  padding: 25px;
  list-style: none;
  //-webkit-transition: var(--transition);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  opacity: 0;
  background-color: #ffff;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
  border-radius: 10px
}

.header__mega--menu__li {
  padding: 3px 15px;
  width: 20%
}

.header__mega--menu__li.fullscreen__style {
  width: 100%;
  margin-top: 1.125rem
}

.header__mega--subtitle {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px
}

.header__mega--sub__menu--title {
  font-size: 0.9375rem;
  padding: 8px 0
}

.header__mega--sub__menu--title:hover {
  color: #0d6efd
}

@media only screen and (min-width: 1600px) {
  .main__header--style4 {
    padding: 0 4.375rem
  }
}

.header__search--form__style {
  position: relative;
  width: 240px
}

@media only screen and (min-width: 1600px) {
  .header__search--form__style {
    width: 300px
  }
}

.header__search--form__style input::-webkit-input-placeholder {
  color: #646464
}

.header__search--form__style input::-moz-placeholder {
  color: #646464
}

.header__search--form__style input:-ms-input-placeholder {
  color: #646464
}

.header__search--form__style input:-moz-placeholder {
  color: #646464
}

.header__search--form__input--field {
  width: 100%;
  border: 1px solid #e4e4e4;
  background: #f9f9f9;
  color: #646464;
  height: 2.875rem;
  border-radius: 1.5625rem;
  padding: 0 3.4375rem 0 1.25rem;
  font-size: 0.875rem
}

.header__search--form__input--field:focus {
  border-color: #0d6efd
}

.header__search--form__btn {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  height: 100%;
  background: #0d6efd;
  color: #fff;
  width: 2.8125rem;
  padding: 0;
  border-radius: 0 1.5625rem 1.5625rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.header__search--form__btn:hover {
  background: #6610f2
}

.dropdown-menu {
  display: block;
}
</style>
